import { useState, useCallback } from 'react';
import ValidationService from 'services/ValidationService/ValidationService';
import MembershipService from 'services/MembershipService/MembershipService';

interface NewsletterSubscriptionState {
  email: string;
  isLoading: boolean;
  success: boolean;
  errors: {
    email: boolean;
  };
}

export const useNewsletterSubscription = () => {
  const [state, setState] = useState<NewsletterSubscriptionState>({
    email: '',
    isLoading: false,
    success: false,
    errors: {
      email: false,
    },
  });

  const setEmail = useCallback((email: string) => {
    setState(prevState => ({
      ...prevState,
      email,
      errors: {
        ...prevState.errors,
        email: false,
      },
    }));
  }, []);

  const validateEmail = useCallback(() => {
    const isValid = ValidationService.isValidEmail(state.email);
    if (!isValid) {
      setState(prevState => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          email: true,
        },
      }));
    }
    return isValid;
  }, [state.email]);

  const subscribeToNewsletter = useCallback(async () => {
    if (!validateEmail()) {
      return;
    }
    
    setState(prevState => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      await MembershipService.subscribeToNewsLetter(state.email);
      setState(prevState => ({
        ...prevState,
        isLoading: false,
        success: true,
      }));
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        isLoading: false,
        success: false,
      }));
    }
  }, [state.email, validateEmail]);

  return {
    email: state.email,
    isLoading: state.isLoading,
    success: state.success,
    errors: state.errors,
    setEmail,
    validateEmail,
    subscribeToNewsletter,
  };
};