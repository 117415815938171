import { FC } from 'react';

const TwitterIcon: FC = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7377_1720)">
        <path d="M11.7443 8.59629L18.5693 0.833374H16.952L11.0259 7.5738L6.29267 0.833374H0.833496L7.99101 11.0261L0.833496 19.1667H2.45089L8.70905 12.0486L13.7077 19.1667H19.1668L11.7439 8.59629H11.7443ZM9.52907 11.1159L8.80387 10.1009L3.03366 2.02474H5.51789L10.1745 8.54249L10.8997 9.55745L16.9528 18.0295H14.4685L9.52907 11.1163V11.1159Z" fill="#BAA57E"/>
      </g>
      <defs>
        <clipPath id="clip0_7377_1720">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default TwitterIcon;
