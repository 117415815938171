import DateService from 'services/DateService/DateService';
import {
  CREDIT_CARD_DATE_FORMAT,
  CARD_BRAND_VISA,
  CARD_BRAND_AMEX,
  CARD_BRAND_MASTERCARD,
  CARD_BRAND_DISCOVER,
  CARD_BRAND_DINERS,
  CARD_BRAND_JCB,
  CARD_BRAND_CHINA_UNION_PAY,
  CARD_BRAND_DEFAULT,
} from 'data/constants';
import { CreditCardBrandType } from 'types/types';

class ValidationService {
  static isValidEmail(email: string): boolean {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static isValidPassword(password: string): boolean {
    return password.length > 0;
  }

  static isValidConfirmPassword(
    password: string,
    confirmPassword: string
  ): boolean | undefined {
    if (!password) {
      return;
    }
    return password === confirmPassword;
  }

  static isValidName(name: string): boolean {
    return name.length > 0;
  }

  static isValidFirstName(firstName: string): boolean {
    return firstName.length > 0;
  }

  static isValidLastName(lastName: string): boolean {
    return lastName.length > 0;
  }

  static isValidPhoneNumber(phoneNumber: string): boolean {
    return phoneNumber.length > 0;
  }

  static isValidDataOfBirth(dateOfBirth: string): boolean {
    //regex to check if a birth day of format dd/mm is valid
    const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])$/;
    return regex.test(dateOfBirth);
  }

  static isValidCountryCode(countryCode: string): boolean {
    return countryCode.length > 0;
  }

  static isValidCity(city: string): boolean {
    return city.length > 0;
  }

  static isValidTermsAccept(termsAccept: boolean): boolean {
    return termsAccept;
  }

  static isLetterOrNumber(key: string | number): boolean {
    return !!`${key}`.match(/^[\d\w]$/i);
  }

  static isNumber(key: string): boolean {
    //include special chars
    return !!`${key}`.match(/^[\d]$/i);
  }

  static isValidCreditCardNumber(value: string): boolean {
    return value.length !== 0;
  }

  static isValidCreditCardCvc(value: string): boolean {
    return value.length === 3 || value.length === 4;
  }

  static isValidCreditCardMonth(value: string): boolean {
    return value.length === 2;
  }

  static isValidCreditCardYear(value: string): boolean {
    return value.length === 2;
  }

  static isCreditCardMonthDateValid(month: string): boolean {
    if (month.length < 2) {
      return true;
    }

    return Number(month) > 0 && Number(month) <= 12;
  }

  static isCreditCardYearDateValid(year: string): boolean {
    return !DateService.isBeforeToday(`01-${year}`, CREDIT_CARD_DATE_FORMAT);
  }

  static isValidCreditCardDate(month: string, year: string): boolean {
    return !DateService.isBeforeToday(
      `${month}-${year}`,
      CREDIT_CARD_DATE_FORMAT
    );
  }

  static isValidCreditCardName(value: string): boolean {
    return value.length > 0;
  }

  static getCreditCardType(value: string): CreditCardBrandType {
    let amex = new RegExp('^3[47][0-9]{13}$');
    let visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
    let cup1 = new RegExp('^62[0-9]{14}[0-9]*$');
    let cup2 = new RegExp('^81[0-9]{14}[0-9]*$');

    let mastercard = new RegExp('^5[1-5][0-9]{14}$');
    let mastercard2 = new RegExp('^2[2-7][0-9]{14}$');

    let disco1 = new RegExp('^6011[0-9]{12}[0-9]*$');
    let disco2 = new RegExp('^62[24568][0-9]{13}[0-9]*$');
    let disco3 = new RegExp('^6[45][0-9]{14}[0-9]*$');

    let diners = new RegExp('^3[0689][0-9]{12}[0-9]*$');
    let jcb = new RegExp('^35[0-9]{14}[0-9]*$');

    if (visa.test(value)) {
      return CARD_BRAND_VISA;
    }
    if (amex.test(value)) {
      return CARD_BRAND_AMEX;
    }
    if (mastercard.test(value) || mastercard2.test(value)) {
      return CARD_BRAND_MASTERCARD;
    }
    if (disco1.test(value) || disco2.test(value) || disco3.test(value)) {
      return CARD_BRAND_DISCOVER;
    }
    if (diners.test(value)) {
      return CARD_BRAND_DINERS;
    }
    if (jcb.test(value)) {
      return CARD_BRAND_JCB;
    }
    if (cup1.test(value) || cup2.test(value)) {
      return CARD_BRAND_CHINA_UNION_PAY;
    }
    return CARD_BRAND_DEFAULT;
  }
}

export default ValidationService;
