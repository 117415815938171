import React, { useCallback, ChangeEventHandler, KeyboardEventHandler } from 'react';
import TextField from 'components/TextField/TextField';
import { useNewsletterSubscription } from 'customHooks/useNewsletterSubscription/useNewsletterSubscription';
import InfoIcon from '/assets/svg/newsletter_send.svg';
import Spinner from 'components/Spinner/Spinner';

export const NewsLetterSubscriptionFooter = () => {
  const {
    email,
    isLoading,
    success,
    errors,
    setEmail,
    subscribeToNewsletter,
  } = useNewsletterSubscription();

  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    if (!success) {
      setEmail(e.target.value);
    }
  }, [setEmail, success]);

  const handleKeyPress = useCallback<KeyboardEventHandler<HTMLInputElement>>((e) => {
    if (e.key === 'Enter' && !isLoading && !success) {
      e.preventDefault();
      subscribeToNewsletter();
    }
  }, [subscribeToNewsletter, isLoading, success]);

  const handleSubscribe = useCallback(() => {
    if (!isLoading && !success) {
      subscribeToNewsletter().then(() => {
        if (success && !errors.email) {
          setEmail('');
        }
      });
    }
  }, [subscribeToNewsletter, isLoading, success, setEmail]);

  return (
    <div className="newsletter-subscription-footer">
      <div className='newsletter-subscription-footer__title'>
       Join our newsletter
      </div>
      <div className='newsletter-subscription-footer__tfContainer'>
        <TextField
          inputClass="newsletter-subscription-footer__textfield"
          onChange={handleChange}
          onKeyUp={handleKeyPress}
          type="email"
          value={success ? '' : email}
          placeholder={success ? "" : "Enter your email"}
          endAdornmentIcon={isLoading ? <Spinner /> : <InfoIcon />}
          endAdomentAction={handleSubscribe}
          disabled={isLoading || success}
          autoComplete="off"
          error={errors.email}
          errorMsg={errors.email ? 'Please enter a valid email address' : ''}
          helperText={success ? 'Thank you for subscribing!' : ''}
          showHelperText={true}
          inputProps={{
            autoComplete: 'off',
          }}
          sx={{
            '& .Mui-disabled': {
              WebkitTextFillColor: '#ffffff !important',
              color: '#ffffff !important',
            },
            '& .MuiFormHelperText-root': {
              color: errors.email ? '#B71610 !important' : '#ffffff !important',
              fontSize: '12px',
              marginTop: '8px',
            },
          }}
        />
      </div>
    </div>
  );
};

export default NewsLetterSubscriptionFooter;