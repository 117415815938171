import moment, { Moment } from 'moment';
import { ARTICLE_DATE_FORMAT } from 'data/constants';

class DateService {
  static getFormattedDateString(date: string, format?: string): string {
    if (format) {
      return moment(date).format(format);
    }
    return moment(date).format(ARTICLE_DATE_FORMAT);
  }

  static getFormattedDateStringFrom(date: Moment|null, format?: string): string|null {
    if(!date) {
      return null
    }
    if (format) {
      return date.format(format);
    }
    return date.format(ARTICLE_DATE_FORMAT);
  }

  static getFormattedDateFromString(dateString: string, format?: string): Moment {
    if (format) {
      return moment(dateString, format);
    }
    return moment(dateString, ARTICLE_DATE_FORMAT);
  }

  static isBeforeToday(dateString: string, dateFormat?: string): boolean {
    const aDate = moment();
    let bDate = moment(dateString);
    if (dateFormat) {
      bDate = moment(dateString, dateFormat);
    }
    return bDate.isBefore(aDate);
  }
}

export default DateService;
